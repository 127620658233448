import React, { Component }  from "react";
import { Link } from 'react-router-dom';
import { t } from "i18next";
import { numberWithSpaces, withRouter } from "../../utils/GlobalFunctions";
//import dayjs from "dayjs";
import { Spin, Statistic,Pagination, Table, Input} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getBaseURL } from "../../utils/axiosRequest";
import moment from "moment";

const { Search } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export class Blanks extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoaded: false,
        processing: true,
        data: null,
        pageSize: 20,
        currentPage: 1,
        searchValue: null,
        tableUpdate: false,
      };
    }

    componentDidMount() {
        this.getBlanks();
    }
    
    getBlanks = () => {
        fetch(`${getBaseURL()}reports/notarius/docs`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.sessionStorage["access_token"],
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              this.setState({ requestFailed: true });
            }
          })
          .then((responseJson) => {
            this.setState({ data: responseJson, isLoaded: true });
          });
      };
    
    onHandlePageChange = (page, size) => {
        this.setState({currentPage: page, pageSize: size, tableUpdate: true});
       
        fetch(`${getBaseURL()}eports/notarius/docs?skip=${(page-1)*size}&limit=${size}&q=${this.state.searchValue}`, 
        {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
                this.setState({ isLoaded:true, requestFailed: true, })
                
                //window.alert('Ҳисобнома ёфт нашуд')
            }
          })
              .then((responseJson) => {
                this.setState({ data : responseJson, isLoaded: true, tableUpdate: false });
                
              });
        //onPageChange(role, size, page, searchValue);
    };

    onBlankSearch = (value) => {
        this.setState({searchValue: value, tableUpdate: true});
        console.log(value)
        fetch(`${getBaseURL()}reports/notarius/docs?q=${value}`, 
        {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
                this.setState({ isLoaded:true, requestFailed: true, })
                
                //window.alert('Ҳисобнома ёфт нашуд')
            }
          })
              .then((responseJson) => {
                this.setState({ data : responseJson, isLoaded: true, tableUpdate: false });
                
              });
        //onPageChange(role, size, page, searchValue);
    };


    render() {
        const {isLoaded, processing, data, pageSize, currentPage, searchValue, tableUpdate} = this.state;
        const blankColumns = [
            {
              title: "Рақами ҳуҷҷат",
              dataIndex: "blank_id",
              key: "blank_id",
              //...getColumnSearchProps('invoice_number', t('INVOICE_NUMBER').toLowerCase()),
              render: (blank_id, row) => {
                return (
                  <div className={row.invoice_number ? 'text-[12px] text-emerald-500': 'text-[12px]'}>
                    {blank_id}
                  </div>);
              },
            },
            {
              title: t("PERFORMER"),
              dataIndex: "performer",
              key: "performer",
              align: "center",
              //...getColumnSearchProps('customer_phonenumber', t('APPLICANT_CONTACTS').toLowerCase()),
              render: (performer, row) => {
                return <div className={row.invoice_number ? 'text-[12px] text-emerald-500': 'text-[12px]'}>{performer}</div>;
              },
            },
            {
                title: t("INVOICE_NUMBER"),
                dataIndex: "invoice_number",
                width: `${window.innerWidth < 700 ? "35%" : "12%"}`,
                key: "invoice_number",
                //...getColumnSearchProps('invoice_number', t('INVOICE_NUMBER').toLowerCase()),
                render: (invoice_number) => {
                  return (<Link to={`/payments/invoice/${invoice_number}`} state={{status: 'Charged'}} >
                          <div className={invoice_number ? 'text-[12px] text-emerald-500': 'text-[12px]'}>{invoice_number}</div>
                      </Link>
                  );
                },
            },
            {
                title: t("DATE"),
                dataIndex: "updated_date",
                key: "updated_date",
                width: `${window.innerWidth < 700 ? "22%" : "auto"}`,
                align: "center",
                render: (updated_date, row) => {
                  return (
                      <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: 'Charged'}} >
                          <div className={row.invoice_number ? 'text-[12px] text-emerald-500': 'text-[12px]'}>{moment(updated_date).format("DD.MM.YYYY  HH:mm")}</div>
                      </Link>
                  );
                },
            }
          ];
        return (
            <div className="flex w-full justify-center"> 
            {!isLoaded ?<div className="w-full flex justify-center"><Spin indicator={antIcon} /></div>
            :<div className="w-full">
                {/* Форма статистики бланков */}
               <div className="w-full sm:hidden lg:flex xl:flex justify-between px-4">
               <div className="w-[33%] bg-white drop-shadow-xl rounded-xl text-center my-2 ">
            
            <Statistic
              title='Бланкҳои дастрас'
              value={data.stats ? numberWithSpaces(data.stats.available.toFixed(0)) : 0.00}
              precision={0}
              valueStyle={{color: '#cf1322', textAlign: 'center'}}
              suffix=""
            />
            <div className="w-full flex justify-center text-slate-400">
                {data.stats ? (100-data.stats.closed/(data.stats.closed + data.stats.available) * 100).toFixed(2) : 0} %
            </div>
            </div>
            
            <div className="w-[33%] bg-white drop-shadow-xl rounded-xl text-center my-2 ">
            
            <Statistic
              title='Бланкҳои истифодашуда'
              value={data.stats ? numberWithSpaces(data.stats.closed.toFixed(0)) : 0.00}
              precision={0}
              valueStyle={{color: '#3f8600', textAlign: 'center'}}
              suffix=""
            />
            <div className="w-full flex justify-center text-slate-400">
                {data.stats ? (data.stats.closed/(data.stats.closed + data.stats.available) * 100).toFixed(2) : 0} %
            </div>
              
            </div>
            
            
            
                </div>

                {/* Таблица бланков */}
                <div className="mt-4">
            <div className="m-2 flex justify-between">
            <Search
                className="p-4"
                id="searchInvoice"
                allowClear
                //onChange={onSearchValueChange}
                placeholder={t("BLANK_SEARCH")}
                onSearch={this.onBlankSearch}
                //value={searchValue}
                enterButton
            />
            
            </div>

            
            {this.state.tableUpdate ? <>
                <div className="w-full h-[10rem] flex items-center justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
            </>
            
            :<> <Table 
                className="md:pl-4"
                dataSource={data.blanks ? data.blanks : null}
                columns={blankColumns} 
                pagination={false} 
                sticky
                size="small"
            >
            </Table>
            <Pagination
                className="mt-[2rem] ml-4"
                onChange={this.onHandlePageChange}
                onShowSizeChange={this.onHandlePageChange}
                total={data.stats ? Math.ceil(data.stats.available + data.stats.closed) : 0}
                current={currentPage}
                pageSize={pageSize}
                hideOnSinglePage={true}
              />
            </>
            }
            
            </div>
            </div>
            }
            </div>
        );

    }
}

export default withRouter(Blanks);