import React, { Component } from "react";
import { withRouter, errorHandler, transliterate } from "../../utils/GlobalFunctions";
import { Tabs, Popover, Input, Table } from "antd";
import { getBaseURL } from "../../utils/axiosRequest";
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from "moment";
import { isMobile } from "react-device-detect";
const { Search } = Input;

export class PaymentForms extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoaded: false,
          data: null,
          requestFailed: false,
          userforms: null,
          searchValue: '',
          searchResult: null,
        };
    }

    componentDidMount() {
        this.getUsersForm();
    }
    
    componentDidUpdate() {}

    getUsersForm = ()=>{
        const today = moment(); 
        var forms = sessionStorage.getItem("Forms")
        
        if (forms === null || forms === undefined || forms === 'undefined' || forms === 'null') {
            this.getForms();
        //this.setState({ userforms : forms, isLoaded: true });  
        }
        else {
            forms =  JSON.parse(forms)
            if (forms[0].loaded !== today.format('YYYY-MM-DD')){
                this.getForms();
            } else {
                this.setState({ userforms : forms, isLoaded: true }); 
            }
        }
    
        
    
    }

    onSearchValueChange = (value) => {
        //if (value) {
          this.setState({searchValue: value});
        //}
      };
    
    onServiceSearch = (value) => {
        this.setState({searchValue: value, searchResult: null});
        if (value){
            var searchArray = [];
            var _index = 0;
            this.state.userforms.foreach((group, _) => {
            group.forms.foreach((form, _)=>{
                if(form.form_id !==0) {
                    
                    var pos = transliterate(
                        form.form_name['tj'].toUpperCase()).search(
                            transliterate(value.toUpperCase())) 
                    if(pos!==-1){
                        //var matched_text = form.form_name['tj'].substring(pos,pos+value.length);
                        var highlited = group.group_name + ' ' + form.form_name['tj'] //.replace(matched_text, '<span className="bg-yellow">'+matched_text+'</span>');
                        var _pos = pos + (group.group_name + ' ').length
                        searchArray[_index] = {result: highlited, start_p: _pos, end_p: _pos + value.length, form: form, service_id: null}
                        
                        _index = _index+1;
                        /*console.log('Совпадает', matched_text, 
                                    'Категория', group.group_name, 
                                    'Форма', highlited
                        );*/
                    }      
                    
                    form.services.foreach((service, _)=>{
                        
                        var pos = transliterate(
                            service.name.toUpperCase()).search(
                                transliterate(value.toUpperCase()))
                        if(pos!==-1){
                            //var matched_text = service.name.substring(pos,pos+value.length);
                            var highlited = group.group_name + ' ' + form.form_name['tj'] + ' ' + service.name //.replace(matched_text, '<span className="bg-yellow">'+matched_text+'</span>')
                            var _pos = pos + (group.group_name + ' ' + form.form_name['tj'] + ' ').length
                            searchArray[_index] = {result: highlited, start_p: _pos, end_p: _pos+value.length, form: form, service_id: service.service_id}
                            _index = _index+1;
                            /*console.log('Совпадает', matched_text, 
                                        'Категория', group.group_name, 
                                        'Форма', form.form_name['tj'], 
                                        'Сервис', highlited
                            );*/
                            
                        };
                    });

                };
    
                    
                })
            })
            this.setState({searchResult: searchArray});
            //console.log(searchArray);
            

        }
        
        
        
        //this.onHandlePageChange( this.state.currentPage, this.state.pageSize);
    };

    getForms=()=>{
        fetch(`${getBaseURL()}forms/userforms`, 
      { 
          method: "GET",
          headers: { 
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + window.sessionStorage["access_token"]
          },
      })
  
      .then((response) => {
        return errorHandler(response, 'PaymentForms.jsx - getUserForm', this.props.navigate)
      })
          .then((responseJson) => {
            sessionStorage.setItem("Forms", JSON.stringify(responseJson));
            this.setState({ userforms : responseJson, isLoaded: true });
            
          });
    }

render() {
    const {isLoaded, userforms, searchValue, searchResult } = this.state;
    const { translation } = this.props;
    
    const columns = [
        {
          title: '',
          dataIndex: "result",
          key: "result",
          render: (result, row) => {
            return (
                <Link className="w-full" to={`/payments/forms/${row.form.form_id}`} state={{formItem: row.form, matchedService: row.service_id}}> 
                    <div className="text-[12px]">
                        {result.substring(0, row.start_p)}
                        <span className="bg-yellow-400 font-semibold text-black">
                            {result.substring(row.start_p, row.end_p)}
                        </span>
                        {result.substring(row.end_p, row.end_p + result.length)}
                    </div>
                </Link>
            );
          },
        },
    ]
    
return (
    
<div className="w-full">{!isLoaded 
    ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
    : 
    <div>
        <div className="md:bg-[#18526B] md:sticky md:top-0 z-[99] flex justify-center p-[1rem] sm:text-base md:text-white uppercase">Рӯйхати хизматрасониҳо</div>
        <Tabs
            
            defaultActiveKey="1"
            tabPosition={isMobile ? "top" : "left"}
            items={ userforms.map((group, i) => {
            const id = String(i + 1);
            return {
                label: (
                    group.group_name === 'Ҷустуҷӯи хизматрасониҳо' ? (
                        <div  className="flex h-auto md:w-[300px]">
                            <SearchOutlined />
                            <div className="truncate sm:px-4 md:px-0">
                                {`${group.group_name}`}
                            </div>
                        </div>
                    ):(
                    group.group_name.length > 38 ? 
                    <Popover overlayStyle={{maxWidth: '300px'}} content={group.group_name} title={''} trigger="hover">
                        <div className="flex h-auto w-[300px]">
                            <div className="truncate">{`${group.group_name}`}</div>
                        </div>
                    </Popover> 
                    : <div  className="flex h-auto md:w-[300px]">
                        <div className="truncate sm:px-4 md:px-0">{`${group.group_name}`}</div>
                    </div>
                    )
                    
                    
                    
                ),
                key: id,
                children: [(group.forms.map((item, id) => {
                    
                    return(
                        item.form_id !== 0 ?
                        <div key={`tbitem_${id}`} className="pt-[1.2rem] w-full justify-center flex">
                            <Link className="w-full" to={`/payments/forms/${item.form_id}`} 
                                state={{formItem: item, units: group.units}} 
                            > 
                            <div className="flex items-center md:w-[80%] min-h-[64px] bg-white p-[20px] m-2 shadow-lg rounded-[8px] border-[1px]">
                                {`${item.form_name.tj}`}
                            </div>
                            </Link>
                        </div>
                        : (<div>
                            <Search
                                className="p-4"
                                id="searchService"
                                allowClear
                                onChange={(e)=>this.onSearchValueChange(e.target.value)}
                                placeholder={translation('SERVICE_SEARCH')}
                                onSearch={this.onServiceSearch}
                                value={searchValue}
                                enterButton
                            />

                            <Table 
                                className='md:pl-4'
                                dataSource={searchResult ? searchResult : null}
                                columns={columns} 
                                pagination={false} 
                                sticky
                                
                                size="small"
                            />
                        </div>)
                        )     
                    }))
                    
                ]

            };
            })}
        />
    </div>
}</div>
)
}

}

export default withRouter(PaymentForms);