import React from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";
import { getBaseURL, getToken } from '../../utils/axiosRequest';
import { errorHandler } from '../../utils/GlobalFunctions';

function Login () {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isAuth = sessionStorage.getItem('isLogged');

const onFinish = (values) => {
    //console.log('onFinish');
    var iv = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.enc.Base64.parse("aR1h7EefwlPNVkvTHwfs6w==");

    var encrypted = CryptoJS.AES.encrypt(values.password, key, { iv: iv });
    var joinedData = iv.clone().concat(encrypted.ciphertext);
    var joinedDataB64 = CryptoJS.enc.Base64.stringify(joinedData);

    const urlencoded = new URLSearchParams();
    urlencoded.append("username", values.username);
    urlencoded.append("password", joinedDataB64);

    fetch(`${getBaseURL()}login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        body: urlencoded
    }).then(response => {return errorHandler(response, 'Login.jsx - onFinish', navigate)})
    .then(data=>{
        sessionStorage.setItem("isLogged", JSON.stringify(true));
        sessionStorage.setItem("access_token", data.access_token);
        const role_id = getToken().role_id;
        console.log(role_id);
        if(role_id === 3) {
            navigate("/payments");
        } else {
            //navigate("https://vkd.smartpay.tj/panel");
            window.location.replace('https://app.smartpay.tj/');
        }
        
        
    });
  };

const onFinishFailed = (errorInfo) => {
    Modal.error({ 
        title: 'Failed!',
        content: errorInfo});
  };

    return (isAuth ? <Navigate to='/payments'/>
    
    :   <div className='bg-white md:w-full text-center sm:h-screen md:h-[100vh]'>
        <div className="pt-[1.75rem] flex items-center bg-transparent w-full rounded-full">
            <img className="m-auto p-0" alt="icon" width="55" src="/assets/img/logos/adliya.png" />

        </div>
        <div className='w-full flex justify-center text-sky-900 p-4'>
            <div className='sm:w-full md:w-1/2 lg:w-1/3 py-[1rem]'>
            Платформа - барои рақамикунонӣ ва таъмини пардохти ғайринақдии хизматрасониҳои муздноки давлатӣ, боҷу хироҷ ва ҷаримаҳои маъмурии Вазорати Адлияи Ҷумҳурии Тоҷикистон
            </div>
        </div>
        
        <div className='flex justify-center'>
            <div className='bg-white rounded-[15px] md:shadow-xl border-[1px] sm:p-[20px] md:p-[40px] sm:w-[90%] md:w-1/2 lg:w-1/3'>
            <Form name="basic" 
                labelCol={{span: 8,}} 
                wrapperCol={{span: 16,}}
                //style={{maxWidth: 600,}}
                initialValues={{remember: true,}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
            <Form.Item
                label='Логин'
                name="username"
                rules={[
                {
                    required: true,
                    message: t("EMAIL"),
                },
                ]}
            >
                <Input />
            </Form.Item>
        
            <Form.Item
                label={t("PASSWORD")}
                name="password"
                rules={[
                {
                    required: true,
                    message: t("PASSWORD"),
                },
                ]}
            >
                <Input.Password />
            </Form.Item>
        
            <Form.Item
                className='w-full flex justify-center'
            >
                <Button type="primary" htmlType="submit">
                {t("ENTER")}
                </Button>
            </Form.Item>
            </Form>
            </div>
        </div>
        </div>
  )};

export default Login;
