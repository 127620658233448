import React, { Component } from "react";
import { t } from "i18next";
import { numberWithSpaces, withRouter } from "../../utils/GlobalFunctions";
import { Table, Popover, Statistic, Pagination, Input } from "antd";
import { getBaseURL } from "../../utils/axiosRequest";
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import moment from "moment";
const { Search } = Input;


export class PaymentList extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoaded: false,
          data: null,
          requestFailed: false,
          userforms: null,
          pageSize: 20,
          currentPage: 1,
          searchValue: '',
          tableUpdate: false,
        };
    }

    componentDidMount() {
        this.getUserInvoices();
    }
    
    componentDidUpdate() {}

    getUserInvoices = ()=>{
        fetch(`${getBaseURL()}invoices/user`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
                this.setState({ isLoaded:true, requestFailed: true, })
                
                //window.alert('Ҳисобнома ёфт нашуд')
            }
          })
              .then((responseJson) => {
                this.setState({ data : responseJson, isLoaded: true });
                
              }); 
    }

    onHandlePageChange = (page, size) => {
        this.setState({currentPage: page, pageSize: size, tableUpdate: true});
       
        fetch(`${getBaseURL()}invoices/user?skip=${(page-1)*size}&limit=${size}&q=${this.state.searchValue}`, 
        {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.sessionStorage["access_token"],
            },
          })
      
          .then((response) => {
            if(response.status === 200){
                return response.json();     
            }else {
                console.log(new Error(response.status))
                this.setState({ isLoaded:true, requestFailed: true, })
                
                //window.alert('Ҳисобнома ёфт нашуд')
            }
          })
              .then((responseJson) => {
                this.setState({ data : responseJson, isLoaded: true, tableUpdate: false });
                
              });
        //onPageChange(role, size, page, searchValue);
    };

    onSearchValueChange = (value) => {
      //if (value) {
        //console.log('Search value', value)
        this.setState({searchValue: value});
      //}
    };
  
    onInvoiceSearch = (value) => {
      this.setState({searchValue: value});
      this.onHandlePageChange( this.state.currentPage, this.state.pageSize);
    };

render() {
    const {isLoaded, data, currentPage, pageSize, searchValue} = this.state;
    const {translation} = this.props;
    const columns = [
        {
          title: t("INVOICE_NUMBER"),
          dataIndex: "invoice_number",
          width: `${window.innerWidth < 700 ? "35%" : "12%"}`,
          key: "invoice_number",
          //...getColumnSearchProps('invoice_number', t('INVOICE_NUMBER').toLowerCase()),
          render: (invoice_number, row) => {
            return (<Link to={`/payments/invoice/${invoice_number}`} state={{status: row.status}} >
                    <div className="text-[12px]">{numberWithSpaces(invoice_number)}</div>
                </Link>
            );
          },
        },
        {
          title: t("DATE"),
          dataIndex: "create_at",
          key: "create_at",
          width: `${window.innerWidth < 700 ? "22%" : "auto"}`,
          align: "center",
          render: (create_at, row) => {
            return (
                <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: row.status}} >
                    <div className="text-[12px]">{moment(create_at).format("DD.MM.YYYY  HH:mm")}</div>
                </Link>
            );
          },
        },
        {
          title: "Рақами ҳуҷҷат",
          dataIndex: "blank_id",
          key: "blank_id",
          width: `${window.innerWidth < 700 ? "22%" : "auto"}`,
          align: "center",
          render: (blank_id, row) => {
            return (
                <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: row.status}} >
                    <div className="text-[12px]">{blank_id}</div>
                </Link>
            );
          },
        },
        {
          title: t("AMOUNT"),
          align: "center",
    
          children: [
            {
              title: t("DEBET"),
              width: `${window.innerWidth > 700 ? "7%" : "auto"}`,
              dataIndex: "amount_paid",
              key: "amount_paid",
              render: (amount_paid, row) => {
                return (
                <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: row.status}} >
                  <div className="text-right text-[12px] text-emerald-500">
                    {amount_paid !== 0 ? (
                      numberWithSpaces((amount_paid / 100).toFixed(2))
                    ) : (
                      <></>
                    )}
                  </div>
                </Link>
                );
              },
            },
            {
              title: t("CREDIT"),
              width: `${window.innerWidth > 700 ? "8%" : "auto"}`,
              dataIndex: "amount_unpaid",
              key: "amount_unpaid",
              render: (amount_unpaid, row) => {
                return (
                <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: row.status}} >
                  <div className="text-right text-[12px] text-[red]">
                    {amount_unpaid !== 0 ? (
                      numberWithSpaces((amount_unpaid / 100).toFixed(2))
                    ) : (
                      <></>
                    )}
                  </div>
                </Link>
                );
              },
            },
          ],
        },
    
        {
          title: t("SERVICES"),
          width: "22%",
          dataIndex: "subject",
          key: "subject",
          className: "",
          //...getColumnSearchProps('subject', t('SERVICES').toLowerCase()),
          render: (subject, row) => {
            return (
              <Popover
                overlayStyle={{ maxWidth: "400px" }}
                content={subject}
                title={t("GOV_SERVICE")}
                trigger="hover"
              >
                <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: row.status}} >
                <p className="mline-clamp">{subject}</p>
                </Link>
              </Popover>
            );
          },
        },
    
        {
          title: t("PAYER"),
          dataIndex: "fullName",
          width: "22%",
          key: "fullName",
          //...getColumnSearchProps('fullName', t('PAYER').toLowerCase()),
          render: (fullName, row) => {
            return (
                <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: row.status}} >
                <div className="uppercase text-[12px]">{fullName}</div>
                </Link>
            );
          },
        },
    
        /*{
          title: t("PAYMENT_DOCUMENT"),
          align: "center",
          dataIndex: "status",
          key: "status",
          /*filters: [
            {text: t("ELECTRONIC"),
              value: 'Charged',},
            {text: t("BANKING"),
              value: 'paid',},
            {text: t("OPEN_INVOICE"),
              value: 'open',},
            {text: t("CANCELED"),
              value: 'void',}
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0,
          render: (status, row) => {
            return (
              <div className="text-center text-[12px]">
                {status === "paid" || status === "Charged" ? (
                  <Tag
                    className="flex items-center justify-center border-none bg-[#E9FFF1] rounded-[15px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      //onShowFile(row);
                    }}
                  >
                <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#006D7D"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                          />
                        </svg>
                        <span className="text-[#36BD87] pl-2">
                          {t("VIEW_DOCUMENT")}
                        </span>
                </>
                    
                  </Tag>
                ) : status === "void" ? (
                  <>
                    <span className="text-red-600 pl-2">{t("CANCELED")}</span>
                  </>
                ) : <></>}
              </div>
            );
          },
        },*/
    
        {
          title: t("PAYER"),
          dataIndex: "fullName",
          key: "fullName",
          //...getColumnSearchProps('create_by', t('PERFORMER').toLowerCase()),
          render: (fullName, row) => {
            /* console.log(row.amount_unpaid); */
            return (
                <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: row.status}} >
                <div className={`${row.amount_unpaid === 0 ? 'text-green-500' : 'text-red-500'} text-[12px]`}>{fullName}</div>
              </Link>
            );
          },
        },
        {
          title: t("PERFORMER"),
          dataIndex: "create_by",
          key: "create_by",
          //...getColumnSearchProps('create_by', t('PERFORMER').toLowerCase()),
          render: (create_by, row) => {
            /* console.log(row.amount_unpaid); */
            return (
                <Link to={`/payments/invoice/${row.invoice_number}`} state={{status: row.status}} >
                <div className={`${row.amount_unpaid === 0 ? 'text-green-500' : 'text-red-500'} text-[12px]`}>{create_by}</div>
              </Link>
            );
          },
        },
      ];

return (
<div className="w-full">{!isLoaded 
    ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
    : <div className="w-full">
        <div className="w-full sm:hidden lg:flex xl:flex justify-between px-4">
            <div className="w-[22%] bg-white drop-shadow-xl rounded-xl text-center my-2">
            
              <Statistic
                title={`${translation("PAID_INVOICE")}`}
                value={data.stats ? numberWithSpaces((data.stats.charged_summ).toFixed(0)) : 0.00} 
                precision={2}
                valueStyle={{color: '#3f8600', textAlign: 'center', textSize: '10px'}}
                suffix="c."
              />
              <div className="w-full flex justify-center text-slate-400">
                {data.stats ? (data.stats.charged_summ/(data.stats.charged_summ + data.stats.open_summ) * 100).toFixed(2) : 0} %
              </div>
            
              
            </div>
            <div className="w-[22%] bg-white drop-shadow-xl rounded-xl text-center my-2 ">
            
            <Statistic
              title={translation("UNPAID_INVOICE")}
              value={data.stats ? numberWithSpaces(data.stats.open_summ.toFixed(0)) : 0.00}
              precision={2}
              valueStyle={{color: '#cf1322', textAlign: 'center'}}
              
              suffix="c."
            />
              <div className="w-full flex justify-center text-slate-400">
                {data.stats ? (100-data.stats.charged_summ/(data.stats.charged_summ + data.stats.open_summ) * 100).toFixed(2) : 0} %
              </div>
              
            </div>
            
            <div className="w-[22%] bg-white drop-shadow-xl rounded-xl text-center my-2 ">
            
            <Statistic
              title={translation("PERFORMED_COUNT")}
              value={data.stats ? numberWithSpaces(data.stats.charged_qty.toFixed(0)) : 0.00}
              precision={0}
              valueStyle={{color: '#3f8600', textAlign: 'center'}}
              suffix=""
            />
            <div className="w-full flex justify-center text-slate-400">
                {data.stats ? (data.stats.charged_qty/(data.stats.charged_qty + data.stats.open_qty) * 100).toFixed(2) : 0} %
              </div>
              
            </div>
            
            <div className="w-[22%] bg-white drop-shadow-xl rounded-xl text-center my-2 ">
            
            <Statistic
              title={translation("TO_PERFORM")}
              value={data.stats ? numberWithSpaces(data.stats.open_qty.toFixed(0)) : 0.00}
              precision={0}
              valueStyle={{color: '#cf1322', textAlign: 'center'}}
              suffix=""
            />
            <div className="w-full flex justify-center text-slate-400">
                {data.stats ? (100-data.stats.charged_qty/(data.stats.charged_qty + data.stats.open_qty) * 100).toFixed(2) : 0} %
            </div>
            </div>
            
        </div>
        <div className="mt-4">
            <div className="m-2 flex justify-between">
            <Link to ='/payments/forms' className="flex bg-emerald-600 mr-[2rem] text-white m-2 lg:text-md xl:text-lg sm:px-3 sm:py-1 md:px-3 lg:px-5 xl:px-10 md:py-1 lg:py-1 xl:py-1 whitespace-nowrap rounded-[8px]">
              <div className="flex items-center">
                <div className="flex mr-4 items-center">
                  <PlusCircleOutlined color="#fff" />
                </div>
                <div>{t("CREATE_INVOICE")}</div>
              </div>
            </Link>
            <Search
                className="p-4"
                id="searchInvoice"
                allowClear
                onChange={(e)=>this.onSearchValueChange(e.target.value)}
                placeholder={t("INVOICE_SEARCH")}
                onSearch={this.onInvoiceSearch}
                value={searchValue}
                enterButton
            />
            
            </div>

            
            {this.state.tableUpdate ? <>
                <div className="w-full h-[10rem] flex items-center justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
            </>
            
            :<> <Table 
                className="md:pl-4"
                dataSource={data.invoices ? data.invoices : null}
                columns={isMobile ? columns.filter((elem, index) => index < 3 ): columns.filter((elem, index) => index !== 5)} 
                pagination={false} 
                sticky
                size="small"
            >
            </Table>
            <Pagination
                className="mt-[2rem] ml-4"
                onChange={this.onHandlePageChange}
                onShowSizeChange={this.onHandlePageChange}
                total={data.stats ? Math.ceil(data.stats.open_qty + data.stats.charged_qty) : 0}
                current={currentPage}
                pageSize={pageSize}
                hideOnSinglePage={true}
              />
            </>
            }
            
        </div>
    </div>
}</div>
)
}

}

export default withRouter(PaymentList);