import React, { Component } from "react";
import { Outlet, Link } from "react-router-dom";
import { t } from "i18next";
import { Input, message, Modal, Dropdown } from "antd";
import {PhoneOutlined, MailOutlined, SettingOutlined, LogoutOutlined, LoadingOutlined, MenuOutlined, AuditOutlined, CreditCardOutlined } from "@ant-design/icons";
import { CustomNavLink } from "../components/Custom/Navlink/CustomNavlink";
import { withRouter, getBaseURL, getToken, errorHandler, destroyToken } from "../utils/GlobalFunctions";
import { isMobile } from "react-device-detect";
import { axiosRequest } from "../utils/axiosRequest";

export class OperatorLayout extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isLoaded: false,
          merchant_data: null,
          user_data: null,
          requestFailed: false,
          btnClass: 'block hamburger md:hidden focus:outline-none',
          //menuClass: 'hidden',
          changePassModal: false,
          errorBool: false,
        };
    }

    componentDidMount() {
        this.getMerchant();
        this.setState({user_data: getToken()})
    }
    
    componentDidUpdate() {}

    getMerchant = () => {
        fetch(`${getBaseURL()}merchants/`, {
                method: "GET",
                headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.sessionStorage["access_token"],
                },
            })
        .then((response) => {
            return errorHandler(response, 'OperatorLayout.jsx - getMerchant', this.props.navigate); 
            })
        .then((responseJson) => {
                this.setState({ merchant_data : responseJson, isLoaded: true });
            }); 
    }

    logout = () => {
        sessionStorage.clear();
        localStorage.clear();
        destroyToken();
        this.props.navigate("/");
    };

    changePassword = async (e) => {
        e.preventDefault();
        if (e.target["new_password"].value !== e.target["re_password"].value) {
            this.setState({errorBool: true});
          return;
        }
        try {
          await axiosRequest.patch("users/password", {
            new_password: e.target["new_password"].value,
            old_password: e.target["old_password"].value,
          });
          this.state({changePassModal: false});
          e.target.reset();
          message.success("Пароль успешно изменен!");
        } catch (error) {
          message.error(error.message);
        }
      };

render() {
    const { isLoaded, user_data, merchant_data, errorBool, changePassModal } = this.state;

    const menu_items = [
        {key: 1, label: (<Link to='payments' className="text-base m-2 text-[#006174] flex items-center">
                          <CreditCardOutlined />{" "}
                          <span style={{ marginLeft: 5 }}>{t("INVOICES")}</span>
                        </Link>)
        },
        {key: 2, label: (<Link to='payments/forms' className="text-base m-2 text-[#006174] flex items-center">
                          <AuditOutlined />{" "}
                          <span style={{ marginLeft: 5 }}>{t("SERVICES")}</span>
                        </Link>)
        },
        {key: 3, label: (<h2 onClick={() => {this.setState({changePassModal: true});}} className="text-base m-2 text-[#006174] flex items-center">
                          <SettingOutlined />{" "}
                          <span style={{ marginLeft: 5 }}>{t("CHANGE_PASSWORD")}</span>
                        </h2>)
        },
        {key: 4, label: (<h2 onClick={this.logout} className="text-base m-2 text-[#006174] flex items-center">
                          {" "}
                          <LogoutOutlined />
                          <span style={{ marginLeft: 5 }}>{t("EXIT")}</span>{" "}
                        </h2>)
        },
    ];

    const items = (isMobile ? menu_items : menu_items.filter((elem, index)=>index > 1 ))
    

    return (
        <div className="w-full">{!isLoaded 
            ? <div className="w-full flex justify-center"><LoadingOutlined style={{ fontSize: 24, }} spin /></div>
            :
<div className="md:flex md:flex-row md:justify-between">
        <div className={`bg-[#18526B] print:hidden md:h-screen overflow-auto flex 
            flex-col justify-between space-y-6 basis-0 md:basis-[18%] py-4`}>
            <div className="w-full">
            {!isMobile ? <>
                <div className="flex flex-row-reverse md:flex-row items-center space-x-0 md:space-x-2 md:border-b-[1px] pb-4">
                <div className="ml-2">
                    
                    <Dropdown menu={{items}}>
                    <button className="w-[45px] h-[45px] text-center rounded-full border border-[#468597] bg-[#D7E5E9] text-[#3E6572]">
                        {user_data.lastname[0] + user_data.firstname[0]}
                    </button>
                    </Dropdown>
                </div>
                <div className="sm:pl-4 md:pl-0">
                    <p className="mb-2 text-base whitespace-pre-line leading-none text-white font-semibold">
                    {`${user_data.lastname} ${user_data.firstname}`}
                    </p>
                    <p className="text-xs leading-none text-white flex flex-row md:justify-start">
                    {user_data.job_title}
                    </p>
                </div>
                
                </div>
                <div className="w-full justify-center bg-[#18526B] md:my-0 lg:my-0 xl:mb-10 xl:mt-1 md:flex-col xl:flex-row items-center lg:space-x-0 xl:space-x-3 md:pl-0 lg:pl-0">
                <div className="py-4 px-2 text-[12px] md:text-center lg:text-center xl:text-center text-white uppercase">
                {merchant_data.merchant.tj
                }
                </div>
                <div className="flex items-center justify-center bg-transparent h-[75px]">
                    <img
                    className="m-auto p-0"
                    src={merchant_data.logo_url}
                    alt="icon"
                    width={45}
                    />
                </div>
                <div className="px-2 pt-4 text-[12px] md:text-center lg:text-center xl:text-center text-white">
                {merchant_data.name.tj ||
                    merchant_data.name.ru ||
                    merchant_data.name.en ||
                    "Smartpay"}
                </div>
            </div>
            <div className="md:text-sm lg:text-sm xl:text-base">
                
                {user_data.role_id === 3 ? (
                <>
                    <CustomNavLink
                    to="/payments"
                    icon='/assets/img/sidebar/invoices2.svg'
                    styles="hover:bg-[#19779F] md:px-3 my-3 lg:px-4 xl:px-8 py-3 flex flex-row space-x-4 items-center hover:cursor-pointer transition-all easy-linear duration-150"
                    title={t("INVOICES")}
                    />
                    <CustomNavLink
                    to="/payments/forms"
                    icon='/assets/img/sidebar/servicesIcon.svg'
                    styles="hover:bg-[#19779F] md:px-3 my-3 lg:px-4 xl:px-8 py-3 flex flex-row space-x-4 items-center rounded-l-[8px] hover:cursor-pointer transition-all easy-linear duration-150"
                    title={t("SERVICES")}
                    />
                    {
                       user_data.muc === 2 ? (
                        <CustomNavLink
                            to="/blanks"
                            icon='/assets/img/sidebar/formsIcon.svg'
                            styles="hover:bg-[#19779F] md:px-3 my-3 lg:px-4 xl:px-8 py-3 flex flex-row space-x-4 items-center rounded-l-[8px] hover:cursor-pointer transition-all easy-linear duration-150"
                            title="Бланкҳо"
                    />
                       ) 
                       : (<></>) 
                    }
                    {<CustomNavLink
                    to="/reports"
                    icon='/assets/img/sidebar/usersIcon.svg'
                    styles="hover:bg-[#19779F] md:px-3 my-3 lg:px-4 xl:px-8 py-3 flex flex-row space-x-4 items-center rounded-l-[8px] hover:cursor-pointer transition-all easy-linear duration-150"
                    title={t("REPORTS")}
                    />}  
                </>
                ):<></>}
                
            </div></> 
            : <>
                <div className="flex w-full">
                
                <div className="w-1/2 sm:pl-4 md:pl-0">
                    <p className="mb-2 text-base whitespace-pre-line leading-none text-white font-semibold">
                    {`${user_data.lastname} ${user_data.firstname}`}
                    </p>
                    <p className="text-xs leading-none text-white flex flex-row md:justify-start">
                    {user_data.job_title}
                    </p>
                </div>

                <div className="w-1/2 flex mr-2 justify-end">
                    
                    <Dropdown menu={{items}}>
                    
                        <MenuOutlined style={{color: 'white', fontSize: '28px'}}/>
                    
                    </Dropdown>
                </div>
                
                </div>
            </>
        }
            </div>
            
            {!isMobile ? <><div className="w-full flex flex-col space-y-4 justify-center items-center border-t pt-5">
            
            <img
                src="https://smartpay.tj/assets/img/smartpay_logo.svg"
                alt="Smartpay"
                width={120}
            />
            <div className="text-white text-[10px]">v2.0.3</div>
            <div className="flex flex-col space-y-1">
                <div className="flex items-center flex-row lg:space-x-1 xl:space-x-3">
                {/* <img className="md:hidden lg:block xl:block" src={contactIcon} alt="icon"/> */}
                <PhoneOutlined className="text-[#fff]" />
                <a
                    href="tel:+992446400019"
                    type="tel"
                    className="text-white mb-1 text-[12px]"
                >
                    {t("SMARTPAY_PHONE")}
                </a>
                </div>
                <div className="flex flex-row lg:space-x-1 xl:space-x-3 items-center">
                {/* <img className="md:hidden lg:block xl:block" src={messageIcon} alt="icon"/> */}
                <MailOutlined className="text-[#fff]" />
                <div className="text-white mb-1 text-[12px]"
                >
                    {t("SMARTPAY_EMAIL")}
                </div>
                </div>
            </div>
            </div> </> : <></>}
        </div>
        
        <div className={`md:bg-[#F4F7F9] basis-full md:basis-[82%] md:h-screen pb-10 md:overflow-auto print:basis-[100%] print:bg-white`}>

            <Outlet />

            {/* Form Change Password Start  */}
            <Modal
            title="Ивази рамз (калидвожа)"
            open={changePassModal}
            onCancel={() => {this.setState({changePassModal: false});}}
            footer
            >
            <form onSubmit={this.changePassword}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                <label>
                    Рамзи пешинара ворид намоед:
                    <Input.Password name="old_password" required />
                </label>
                <label>
                    Рамзи навро ворид намоед:
                    <Input.Password
                    name="new_password"
                    status={errorBool ? "error" : ""}
                    required
                    />
                </label>
                <label>
                    Рамзи навро такрор ворид намоед:
                    <Input.Password
                    name="re_password"
                    status={errorBool ? "error" : ""}
                    required
                    />
                </label>
                {errorBool && (
                    <div style={{ margin: "5px", color: "red" }}>
                    Рамзи нави воридшуда мувофиқат намекунад
                    </div>
                )}
                </div>
                <div className="mt-8 flex w-full justify-center">
                <button
                    className="m-2 p-2 w-1/2 rounded-lg bg-indigo-400 text-[white] hover:bg-indigo-600 hover:font-semibold"
                    type="submit"
                >
                    Равон кардан
                </button>
                </div>
            </form>
            </Modal>
            {/* Form Change Password End  */}
        </div>
</div>
        } </div>
    )
}


}

export default withRouter(OperatorLayout);

